import React, { useState, useEffect } from "react";
import { Section, SectionHeader } from "../../shared";
import styled from "styled-components";
import logo from "../../../assets/GS_Logo_M.png";
import { colors } from "../../../colors";
import { useQuery } from "@apollo/react-hooks";
import gql from "graphql-tag";
import { getCookie } from "../../../utility";

const GET_VENUES_AND_OFFERS = gql`
  query {
    allActiveVenuesAndOffers {
      edges {
        node {
          venueId
          phone
          website
          address
          city
          state
          zipCode
          logo
          name
          area
          offers {
            offerId
            endDate
            header
            subheader
            description
            disclaimer
            offerType
            image
            maxValue
            redemptionPeriod
            allowedRedemptions
            startDate
            endDate
            offerStatus
          }
        }
      }
    }
  }
`;

const GET_USER_REDEMPTIONS = gql`
  query($cookie: String!) {
    userRedemptions(cookie: $cookie) {
      offerId
      maxValue
    }
  }
`;

const Divider = styled.div`
  height: 0;
  width: 100%;
  border-top: 1px solid rgba(0, 0, 0, 0.2);
  margin: 10px 0;
`;

const fontSize = "18px";
const Text = styled.span`
  font-weight: 600;
  font-size: ${fontSize};
  color: ${({ color }) => color};
`;

const Value = styled.span`
  font-weight: 650;
  font-size: ${fontSize};
  color: ${({ color }) => color};
`;

const calculateNumberOfRedemptions = (offer) => {
  const redemptionPeriod = JSON.parse(offer?.redemptionPeriod)?.value;
  const endDate = new Date(offer.endDate);
  const end = endDate.getTime();
  const now = new Date().getTime();
  if (end > now) {
    const difference = endDate.getTime() - now;
    const numDays = Math.floor(difference / (1000 * 60 * 60 * 24));
    switch (redemptionPeriod) {
      case "perDay": {
        return offer.allowedRedemptions * (numDays + 1);
      }
      case "perWeek": {
        return offer.allowedRedemptions * Math.ceil(numDays / 7);
      }
      case "perMonth": {
        return offer.allowedRedemptions * Math.ceil(numDays / 30.42);
      }
      case "perYear": {
        return offer.allowedRedemptions * Math.ceil(numDays / 365);
      }
      default:
        return 0;
    }
  }
  return 0;
};

export const OfferStats = () => {
  const { loading, error, data } = useQuery(GET_VENUES_AND_OFFERS, {
    fetchPolicy: "no-cache"
  });
  const userRedemptions = useQuery(GET_USER_REDEMPTIONS, {
    variables: { cookie: getCookie("uuid") },
    fetchPolicy: "no-cache"
  });
  const [venueOffers, setVenueOffers] = useState({});
  const [myRedemptions, setMyRedemptions] = useState({});
  const [myStats, setMyStats] = useState({});

  useEffect(() => {
    let totalVenues = 0;
    let totalOffers = 0;
    let totalValue = 0;
    let allOffers = [];
    if (data) {
      data.allActiveVenuesAndOffers.edges.map((edge) => {
        totalVenues++;
        edge.node.offers.forEach((node) => {
          const numRedemptionLeft = calculateNumberOfRedemptions(node);
          totalOffers += numRedemptionLeft;
          totalValue += numRedemptionLeft * node.maxValue;
          allOffers.push(node.offerId);
        });
      });
    }
    setVenueOffers(
      Object.assign({
        totalVenues,
        totalOffers,
        totalValue,
        allOffers
      })
    );
  }, [data, setVenueOffers]);

  useEffect(() => {
    let offersRedeemed = [];
    if (userRedemptions.data) {
      offersRedeemed = userRedemptions.data.userRedemptions;
      setMyRedemptions({ offersRedeemed });
    }
  }, [userRedemptions]);

  // not working
  useEffect(() => {
    let mySavings = 0;
    // let offersAvailable = 0;
    // let valueAvailable = 0;
    let offers = [];
    let all_offers = [];
    if (data && data.allActiveVenuesAndOffers) {
      data.allActiveVenuesAndOffers.edges.forEach((edge) => {
        const activeOffers = [];
        edge.node.offers.forEach(offer => {
          if (offer.offerStatus !== "inactive") {
            activeOffers.push(offer);
          }
        })
        offers = offers.concat(activeOffers);
        all_offers = all_offers.concat(edge.node.offers);
      });
    }
    if (all_offers && userRedemptions.data && userRedemptions.data.userRedemptions) {
      userRedemptions.data.userRedemptions.forEach((redemption) => {
        mySavings += all_offers.find((offer) => offer.offerId === redemption.offerId)?.maxValue || 0;
      });
    }
    setMyStats({ mySavings });
  }, [data, userRedemptions]);

  return (
    <Section>
      <SectionHeader>Stats</SectionHeader>
      <Divider />
      <Text>
        Total Courses: <Value color={colors.totalStats}>{venueOffers.totalVenues}</Value>
      </Text>
      <Divider />
      <Text>
        Total Offers: <Value color={colors.totalStats}>{venueOffers.totalOffers}</Value>
      </Text>
      <Divider />
      <Text>
        Total Value: <Value color={colors.totalStats}>{`$${venueOffers.totalValue}`}</Value>
      </Text>
      <Divider />
      {/* <Text color="green"> */}
      <Text>
        Offers Redeemed:{" "}
        <Value color={colors.success}>
          {myRedemptions.offersRedeemed && myRedemptions.offersRedeemed.length}
        </Value>
      </Text>
      <Divider />
      {/* <Text color="green"> */}
      <Text>
        My Savings: <Value color={colors.success}>${myStats.mySavings}</Value>
      </Text>
      <Divider />
      {/* <Text color="red">
      <Text>
        Offers Available: <Value color={colors.danger}>93</Value>
      </Text>
      <Divider />
      <Text>
        Value Available: <Value color={colors.success}>$557.00</Value>
      </Text>
      <Divider /> */}
    </Section>
  );
};
