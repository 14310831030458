import DatePicker from "react-date-picker";
import { Link } from "react-router-dom";
import { HashLink } from "react-router-hash-link";
import Select from "react-select";
import CreatableSelect from "react-select/creatable";
import TimePicker from "react-time-picker";
import styled, { keyframes } from "styled-components";
import { colors } from "../../colors";

const padding = "10px";
const backgroundColor = "white";
const borderRadius = "20px";
export const boxShadow = "5px 5px 10px rgba(0,0,0, .8)";
const width = "88%";
const margin = "10px auto";

export const Section = styled.section`
  display: block;
  text-align: center;
  align-items: center;
  padding: ${padding};
  background-color: ${backgroundColor};
  border-radius: ${borderRadius};
  box-shadow: ${boxShadow};
  width: ${width};
  margin: ${margin};
`;

export const SectionHeader = styled.h2`
  margin-bottom: 15px;
  padding: 0;
  font-size: 22px;
`;

export const StyledLink = styled(Link)`
  text-decoration: none;
  padding: 5px 10px;
  border-radius: 10px;
  background-color: ${colors.navLinkBg};
  color: ${colors.navLinkFont};
  margin: 5px;
`;

export const ButtonLinkStyles = `
  padding: 8px 15px;
  border-radius: 15px;
  border: none;
  background-color: ${colors.navLinkBg};
  color: ${colors.navLinkFont};
  margin: 20px 0;
  font-weight: bold;
  text-decoration: none;
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;

  &[disabled] {
    pointer-events: none;
    background-color: ${colors.disabled};
  }

  &:active,
  :focus {
    background-color: ${colors.primaryChange};
  }
`;

export const ButtonLink = styled(HashLink)`
  ${ButtonLinkStyles}
`;

// export const Button = styled.button`
//     padding: 8px 15px;
//     border-radius: 15px;
//     border: none;
//     background-color: ${colors.navLinkBg};
//     color: ${colors.navLinkFont};
//     margin: 20px;
//     font-weight: bold;
// `;

export const SubmitButton = styled.input.attrs({ type: "submit" })`
  padding: 8px 15px;
  border-radius: 15px;
  border: none;
  background-color: ${colors.navLinkBg};
  color: ${colors.navLinkFont};
  margin: 20px;
  font-weight: bold;
  height: 40px;
`;

export const LoginSubmitBtnStyles = `
  background-color: ${colors.primary};
  color: white;
  height: 45px;
  width: 200px;
  border-radius: 10px;
  border: none;
  font-size: 20px;
  font-weight: bold;
  max-width: 100%;
  min-width: 225px;
  margin: 20px auto;
  width: calc(100% - 40px);
  text-decoration: none;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const LoginSubmitBtn = styled.button`
  ${LoginSubmitBtnStyles}
`;

export const Flex = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Form = styled.form`
  max-width: 700px;
  padding-bottom: 100px;
`;

export const Divider = styled.div`
  height: 0;
  width: 100%;
  border: 1px solid rgba(0, 0, 0, 0.8);
  margin: 10px 0;
`;

export const BaseLabelStyles = `
    color: ${colors.defaultBgOffset};
    min-height: 38px;
    font-weight: 500;
    white-space: nowrap;
    cursor: pointer;

    &[required] > span {
        &::before {
            content: "•";
            color: ${colors.required};
            margin-right: 3px;
        }
    }

    &[disabled] {
        cursor: not-allowed;
    }
`;

export const BaseLabel = styled.label`
  ${BaseLabelStyles};
`;

export const BaseInputStyles = `
    height: 38px;
    border: 1px solid ${colors.inputBorder};
    font-size: 16px;
    padding: 6px 6px 2px 6px;
    box-sizing: border-box;
    border-radius: 4px;

    &[disabled] {
        cursor: not-allowed;
    }
`;

export const BaseInput = styled.input`
  ${BaseInputStyles}
`;

export const RadioContainer = styled.div`
  width: calc(100% - 140px);
  max-width: 500px;
  display: flex;
  justify-content: flex-start;
`;

export const RadioLabel = styled.label`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-right: 25px;
`;

export const RadioInput = styled.input.attrs({ type: "radio" })`
  margin-right: 5px;

  &[data-required="true"] {
    box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.075) inset, 0px 0px 8px red;
  }
`;

export const Label = styled(BaseLabel)`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 6px 20px 2px 20px;
`;

export const Text = styled.span`
  min-width: 120px;
  margin-right: 20px;
  line-height: 38px;
`;

export const TextInput = styled(BaseInput).attrs({ type: "text" })`
  width: ${({ width }) => width || "calc(100% - 140px)"};
  max-width: 500px;

  &[data-required="true"] {
    border-color: red;
  }
`;

export const PasswordContainer = styled.div`
  position: relative;
  width: calc(100% - 140px);
  max-width: 500px;
  display: flex;
`;

export const PasswordInput = styled(BaseInput)`
  width: 100%;
  padding-right: 35px;
`;

export const PasswordToggle = styled.div`
  position: absolute;
  right: 10px;
  height: 100%;
  max-width: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const NumberInput = styled(BaseInput).attrs({ type: "text" })`
  width: 40px;
`;

export const MultipleInputWrapper = styled.div`
  ${BaseLabelStyles}
  display: flex;
  justify-content: flex-start;
  padding: 6px 20px 2px 20px;
`;

export const MultipleInputText = styled.span`
  margin-right: 20px;
  font-weight: normal;
`;

export const MultipleInputLabel = styled(BaseLabel)`
  display: flex;
  align-items: center;
  margin-right: 15px;
  justify-content: flex-start;
`;

export const selectStyles = `
    width: 100%;
    min-width: 200px;
    max-width: 400px;

    &[data-enableRequired="true"] {
      border: red;
    }

`;

export const StyledSelect = styled(Select)`
  ${selectStyles}
`;

export const StyledCreatableSelect = styled(CreatableSelect)`
  ${selectStyles}
`;

export const CheckboxInput = styled.input.attrs({ type: "checkbox" })`
  &[data-required="true"] {
    box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.075) inset, 0px 0px 8px red;
  }
`;

export const InlineRadioWrapper = styled.div`
  display: flex;
`;

export const ManagerButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5px 10px;
  border-radius: 7.5px;
  background-color: ${colors.primary};
  color: ${colors.primaryOffset};
  border: none;
  font-size: 16px;
  margin: 0 5px;

  &[disabled] {
    background-color: ${colors.disabled};
    cursor: not-allowed;
  }
`;

export const ManagerButtonText = styled.span`
  margin-left: 5px;
  padding-right: 5px;
`;

export const StyledDatePicker = styled(DatePicker)`
  background-color: white;
  height: 38px;

  & > div {
    padding: 0 5px;
    border: 1px solid ${colors.inputBorder};
    border-radius: 4px;
  }
`;

export const StyledTimePicker = styled(TimePicker)`
  background-color: white;
  height: 38px;
  font-weight: normal;
  min-width: 100px;

  & > div {
    border: 1px solid ${colors.inputBorder};
    border-radius: 4px;
  }
`;

export const RadioText = styled.span`
  font-weight: normal;
`;

export const LoaderWrapper = styled.div`
  position: fixed;
  height: 100vh;
  width: 100vw;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background-color: rgba(0, 0, 0, 0.2);
  top: 0;
  left: 0;
  z-index: 5;
`;

export const ContainerLoaderWrapper = styled.div`
  position: absolute;
  height: 100%;
  width: 100%;
  border-radius: 10px
  background-color: rgba(0,0,0,0.2);
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const LoaderBackground = styled.div`
  padding: 0 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: black;
  border-radius: 10px;
`;

export const Loader = styled.div``;

export const ModalWrapper = styled.div`
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.2);
  z-index: 10;
`;

export const ModalHeader = styled.h2`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const ModalLabel = styled.label`
  ${BaseLabelStyles}
  display: flex;
  flex-direction: column;
  white-space: unset;
  align-items: flex-start;
`;

export const ModalInput = styled.input`
  ${BaseInputStyles}
  width: 100%;
  margin-bottom: 15px;
`;

export const Modal = styled.div`
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 15px;
  background-color: ${colors.primary};
  color: white;
  border-radius: 10px;
  max-height: 80vh;
`;

export const ConfirmText = styled.h3`
  padding: 15px;
  font-size: 20px;
  margin: 0 0 20px 0;
`;

export const ConfirmButtonWrapper = styled.div`
  padding-bottom: 15px;
  display: flex;
  justify-content: center;
`;

export const iconSize = "1.5rem";

export const ButtonStyles = `
    margin-right: 25px;
    border: none;
    border-radius: 5px;
    padding: 5px 10px;
    display: flex;
    align-items: center;
    min-height: 38px;
    margin: 0px 5px;
    font-weight: bold;
    justify-content: center;

    &:active, :focus {
      background-color: ${colors.primaryChange};
    }
`;

export const Button = styled.button.attrs({ type: "button" })`
  ${ButtonStyles}
  background-color: ${({ bgColor }) => bgColor || colors.secondary};
  color: ${({ color }) => color || colors.secondaryOffset};
`;

export const Rotate = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`;

export const LoaderText = styled.span`
  margin-bottom: 10px;
  font-weight: bold;
  font-size: 16px;
  color: ${colors.secondary};
  border-radius: 10px;
`;

export const navHeight = "75px";

export const AppWrapper = styled.div`
  height: 100%;
  min-height: 100vh;
  width: 100%;
  background-color: ${colors.secondary};
  background: linear-gradient(
    90deg,
    ${colors.secondary} 0%,
    ${colors.defaultBg} 50%,
    ${colors.secondary} 100%
  );
  display: flex;
  flex-direction: column;
  align-items: center;
  border-top: ${navHeight} solid ${colors.secondary};
  box-sizing: border-box;
`;

export const AppMain = styled.main`
  height: 100%;
  min-height: 100vh;
  width: 100%;
  max-width: 1850px;
  background-color: ${colors.defaultBg};
  overflow: auto;
`;

export const EmojiWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
`;

export const ToastText = styled.span`
  padding: 5px;
`;

export const ManagerWrapper = styled.section`
  min-height: 50%;
  max-width: 500px;
  overflow-y: scroll;
`;

export const UseDetails = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-evenly;
  font-size: 16px;
  font-weight: bold;
  flex-wrap: wrap;
  margin: ${margin};
`;

export const UseDetailsAvailable = styled.span`
  color: green;
`;

export const UseDetailsRedeemed = styled.span`
  color: orange;
`;

export const UseDetailsExpired = styled.span`
  color: red;
`;
